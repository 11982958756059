import Api from '@/mixins/Api';
import PERMISSIONS from '@/store/PermissionConstants';

export default {
  mixins: [Api],
  data() {
    return {
      /** **********************************************************
       * Team CRUD
       *********************************************************** */

      getTeam: {
        fn(teamId) {
          return this.apiCall('GET', 'getTeam', '/team', { teamId })
            .then((result) => {
              if (result.data.league === 'NBDL') result.data.league = 'GLEAGUE';
              return result;
            });
        },
        meta: {
          name: 'getTeam',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Team
        }
      },

      getTeamV2: {
        fn(teamId, params) {
          return this.apiCall('GET', 'getTeamV2', `/team/${teamId}`, params, null, null, true)
            .then((result) => {
              if (result.data?.league === 'NBDL') result.data.league = 'GLEAGUE';
              return result;
            });
        },
        meta: {
          name: 'getTeamV2',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Team
        }
      },

      saveTeam: {
        fn(data) {
          return this.apiCall('POST', 'saveTeam', '/team', null, JSON.stringify(data), { 'Content-Type': 'application/json' });
        },
        meta: {
          name: 'saveTeam',
          permission: PERMISSIONS.write,
          securable: PERMISSIONS.Team
        }
      },

      deleteTeam: {
        fn(teamId) {
          return this.apiCall('DELETE', 'deleteTeam', '/team', { teamId });
        },
        meta: {
          name: 'deleteTeam',
          permission: PERMISSIONS.delete,
          securable: PERMISSIONS.Team
        }
      },

      /** **********************************************************
       * Team Game Logs
       *********************************************************** */

      getTeamGameLogs: {
        fn(params) {
          return this.apiCall('GET', 'getTeamGameLogs', '/vGameTeamLog', params, null, null, true);
        },
        meta: {
          name: 'getTeamGameLogs',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Team
        }
      },

      /** **********************************************************
       * Team Roster / Staff / Assets
       *********************************************************** */

      getTeamRoster: {
        fn(teamId, season, isActive) {
          return this.apiCall('GET', 'getTeamRoster', '/team/rosters', { active: isActive, season, teamId });
        },
        meta: {
          cacheable: true,
          name: 'getTeamRoster',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Roster
        }
      },

      getTeamFinalRosters: {
        fn(teamId, season, params) {
          if (!params) params = {};
          if (teamId) params.tmId = teamId;
          if (season) params.season = season;
          return this.apiCall('GET', 'getTeamFinalRosters', '/teamFinalRosters', params, null, null, true)
            .then((result) => {
              if (result.data.league === 'NBDL') result.data.league = 'GLEAGUE';
              return result;
            });
        },
        meta: {
          name: 'getTeamFinalRosters',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Roster
        }
      },

      saveTeamRoster: {
        fn(data) {
          return this.apiCall('POST', 'saveTeamRoster', '/team/rosters', null, JSON.stringify(data), { 'Content-Type': 'application/json' });
        },
        meta: {
          name: 'saveTeamRoster',
          permission: PERMISSIONS.write,
          securable: PERMISSIONS.Roster
        }
      },

      getTeamStaff: {
        fn(teamId) {
          return this.apiCall('GET', 'getTeamStaff', '/team/staff', { teamId });
        },
        meta: {
          name: 'getTeamStaff',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.Roster
        }
      },

      getTeamContracts: {
        fn(teamId, season) {
          return this.apiCall('GET', 'getTeamContracts', '/contracts/salary-book-ledger/search', { tmId: teamId, seasonAfter: season - 1 });
        },
        meta: {
          name: 'getTeamContracts',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.TeamStanding
        }
      },

      getTeamAssets: {
        fn(teamId) {
          return this.apiCall('GET', 'getTeamAssets', '/team/assets', { teamId });
        },
        meta: {
          name: 'getTeamAssets',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.TeamAsset
        }
      },

      /** **********************************************************
       * Standings
       *********************************************************** */

      getTeamStandings: {
        fn(teamId, season) {
          return this.apiCall('GET', 'getTeamStandings', '/team/standings', { seasonId: season, systemTeamId: teamId });
        },
        meta: {
          name: 'getTeamStandings',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.TeamStanding
        }
      },

      getSeasonStandings: {
        fn(season, isGleague, seasonType) {
          const leagueId = isGleague ? '20' : '00';
          const nextSeason = (season + 1).toString().slice(2);
          const seasonYear = `${season}-${nextSeason}`;
          const params = {
            leagueId,
            seasonYear
          };
          if (seasonType) params.seasonType = seasonType;
          return this.apiCall('GET', 'getSeasonStandings', '/nbaStandings', params, null, null, true);
        },
        meta: {
          name: 'getSeasonStandings',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.TeamStanding
        }
      },

      /** **********************************************************
       * Season Stats
       *********************************************************** */

      getTeamPlayerStats: {
        fn(teamId, season) {
          return this.apiCall('GET', 'getTeamPlayerStats', '/team/player-stats', { teamId, year: season });
        },
        meta: {
          name: 'getTeamPlayerStats',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.PlayerStats
        }
      },

      getTeamStats: {
        fn(params) {
          return this.apiCall('GET', 'getTeamStats', '/teamStats', params, null, null, true);
        },
        meta: {
          cacheable: true,
          name: 'getTeamStats',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.PlayerStats
        }
      },

      /** **********************************************************
       * Misc
       *********************************************************** */

      getTeamPlayerShots: {
        fn(teamParams) {
          return this.apiCall('GET', 'getTeamPlayerShots', '/team/shot', teamParams);
        },
        meta: {
          name: 'getTeamPlayerShots',
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.PlayerShot
        }
      }
    };
  }
};
