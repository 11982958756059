import PERMISSIONS from '@/store/PermissionConstants';

export default [
  // Personnel Wrapper
  {
    path: "/reports/",
    name: "ReportsRoot",
    props: true,
    component: () => import("@/views/reports/ReportsRoot.vue"),
    meta: {
      permission: PERMISSIONS.read,
      primary: "reports",
      securable: PERMISSIONS.AllReports,
    },

    // Children Routes
    children: [
      // Pregames
      {
        path: "/reports/pregame/:reportId",
        name: "ReportsPregame",
        props: true,
        meta: {
          disallowAbort: true,
          pageTitle: "Pregame",
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.PostgameReport,
        },
        component: () => import("@/views/reports/ReportsPregame.vue"),
      },
      {
        path: "/reports/pregame",
        name: "ReportsPregameAll",
        meta: {
          pageInSearch: true,
          pageTitle: "Pregame - All",
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.PostgameReport,
        },
        component: () => import("@/views/reports/ReportsPregameAll.vue"),
      },

      // Faxbacks
      {
        path: "/reports/faxback/:id",
        name: "ReportsFaxback",
        props: true,
        meta: {
          pageTitle: "Faxback",
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.FaxbackReport,
        },
        component: () => import("@/views/reports/ReportsFaxback.vue"),
      },
      {
        path: "/reports/faxback",
        name: "ReportsFaxbackAll",
        props: true,
        meta: {
          pageInSearch: true,
          pageTitle: "Faxback - All",
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.FaxbackReport,
        },
        component: () => import("@/views/reports/ReportsFaxbackAll.vue"),
      },

      // Postgames
      {
        path: "/reports/postgame/:reportId",
        name: "ReportsPostgame",
        props: true,
        meta: {
          pageTitle: "Postgame",
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.PostgameReport,
        },
        component: () => import("@/views/reports/ReportsPostgame.vue"),
      },
      {
        path: "/reports/postgame",
        name: "ReportsPostgameAll",
        props: true,
        meta: {
          pageInSearch: true,
          pageTitle: "Postgame - All",
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.PostgameReport,
        },
        component: () => import("@/views/reports/ReportsPostgameAll.vue"),
      },

      // Index
      {
        path: "/reports/index",
        name: "ReportsIndex",
        props: true,
        meta: {
          pageInSearch: true,
          pageTitle: "Reports Index",
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.AllReports,
        },
        component: () => import("@/views/reports/ReportsIndex.vue"),
      },

      // Scouting Reports
      {
        path: "/reports/players-seen-by-scouts/:listId?",
        name: "ReportPlayersSeenByScout",
        props: true,
        meta: {
          pageInSearch: true,
          pageTitle: "Players Seen By Scouts",
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.SeenByScoutReport,
        },
        component: () =>
          import("@/views/reports/scouting/ReportPlayersSeenByScout.vue"),
      },
      {
        path: "/reports/scout-commentary-summary",
        name: "ScoutCommentarySummary",
        props: true,
        meta: {
          pageInSearch: true,
          pageTitle: "Scout Commentary Summary",
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.CommentaryScoutSummary,
        },
        component: () =>
          import("@/views/reports/scouting/ScoutCommentarySummary.vue"),
      },
      {
        path: "/reports/list-players-roles",
        name: "ListPlayersRoles",
        props: true,
        meta: {
          pageInSearch: true,
          pageTitle: "List Players Roles",
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.ListRolesReport,
        },
        component: () =>
          import("@/views/reports/scouting/ListPlayersRoles.vue"),
      },

      // PostGame Reports

      // NOTE - needs :gameId and db tables to function properly
      {
        path: "/reports/postgame-addendum/",
        name: "PostgameAddendum",
        props: true,
        meta: {
          pageTitle: "Postgame Addendum",
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.PostgameReport,
        },
        component: () =>
          import("@/views/reports/postgame/PostgameAddendum.vue"),
      },

      //GLeague Report
      {
        path: "/reports/gleague",
        name: "ReportsGLeague",
        meta: {
          pageInSearch: true,
          pageTitle: "GLeague",
          permission: PERMISSIONS.read,
          securable: PERMISSIONS.PlayerStats,
        },
        component: () => import("@/views/reports/ReportsGLeague.vue"),
      },

      // Default routes
      {
        path: "/reports/*",
        redirect: "/reports/index",
      },
      {
        path: "/reports",
        redirect: "/reports/index",
      },
    ],
  },
];
